// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.solution {
	color: $text-color-black;
	background: $color-gray;
	@include padding(rem(5px) null rem(5px));

	h2 {
		text-align: center;
		font-family: $font-family-base-medium;
		@include font-size(50px);
		@include margin(null null rem(40px));
		@include heading-line($color-primary);
	}

	ul {

		> li {
			@include margin(null null rem(50px));

			div {
				@include solution-block;
			}

		}

	}

}

