// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.product {
	z-index: 1;
	overflow: hidden;
	color: $text-color-black;
	@include position(relative);
	@include padding(rem(30px) null rem(45px));
	&:after {
		z-index: -1;
		content: "";
		width: 100%;
		height: 120px;
		display: block;
		background: $color-gray;
		@include transform(skewY(-3deg));
		@include position(absolute, null null rem(-50px) 0);
	}

	p {
		line-height: 1.4;
		@include font-size(22px);
		@include margin(null null rem(30px));
	}

	h2 {
		@include margin(null null rem(30px));
	}

	figure {
		@include padding(rem(30px) null null);

		img {
			width: 100%;
			max-width: 520px;
			@include margin(null auto);
		}

	}

	aside {
		@include padding(rem(60px) null null);
	}

}

