// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.wedo {
	color: $color-white;
	@include padding(rem(33px) null);
	@include gradient($color-primary, $color-primary-light, 45deg);

	h2 {
		font-family: $font-family-base-medium;
		@include margin(0);
	}

}
