// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.welcome {
	z-index: 1;
	overflow: hidden;
	text-align: center;
	color: $text-color-black;
	@include position(relative);
	@include padding(rem(50px) null null);
	&:after {
		z-index: -1;
		content: "";
		width: 100%;
		height: 120px;
		display: block;
		background: $color-gray;
		@include transform(skewY(-2deg));
		@include position(absolute, null null rem(-50px) 0);
	}

	> div {
		max-width: 1250px;
	}

	h2 {
		line-height: 1;
		@include font-size(50px);
		@include margin(null null rem(30px));
	}

	p {
		max-width: 770px;
		@include margin(null auto rem(43px));
	}

	a {

		&.button {

			&:after {
				content: "\f105";
				font-family: FontAwesome;
				@include margin(null null null rem(10px));
			}

		}

	}

}
