// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */
.industrial {
	@include padding(rem(50px) null rem(100px));

	> div {

		div {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center top;
			@include padding(rem(30px));
			&:hover {
				z-index: 10;
				box-shadow: 0 0 40px rgba(0,0,0,0.3);
				-webkit-box-shadow: 0 0 40px rgba(0,0,0,0.3);
			}
			@include media($breakpoint-desktop){
				@include padding(rem(15px));
			}

			a {
				display: inline-block;
				text-transform: uppercase;
				color: $text-color-black;
				background: $color-white;
				font-family: $font-family-base-black;
				@include font-size(14px);
				@include border-top-radius(15px);
				@include border-bottom-radius(15px);
				@include padding(rem(5px) rem(25px));
				@include media($breakpoint-desktop){
					@include padding(rem(5px) rem(20px));
				}
				&:hover {
					background: rgba($color-white, 0.8);
				}

			}

			aside {
				max-width: 370px;
				line-height: 1.3;
				color: $color-white;
				font-family: $font-family-base-medium;
				@include font-size(28px);
				@include padding(rem(30px) rem(30px) rem(15px));
				@include position(absolute, null null 0 0);
				@include media($breakpoint-desktop){
					@include font-size(25px);
					@include padding(rem(15px));
				}
				@include media($breakpoint-tablet){
					@include font-size(20px);
				}

				p {
					@include margin(0);
				}

			}

		}

		> ul {
			@include margin(0);

			> li {
				@include clearfix;

				&:first-child {

					> div {
						width: 50%;
						float: left;
						min-height: rem(700px);
						@include position(relative);
						@include border-top-radius(10px);
						@include border-right-radius(0);
						@include media($breakpoint-desktop){
							min-height: rem(600px);
						}
						@include media($breakpoint-phone){
							float: none;
							width: 100%;
							@include border-top-radius(10px);
						}

					}

				}

				> div {

					&:last-of-type {
						background-color: transparent;
						@include padding(0);
						&:hover {
							box-shadow: none;
							-webkit-box-shadow: none;
						}
						> ul {
							@include margin(0);

							> li {
								@include clearfix;

								> div {
									width: 100%;
									min-height: rem(350px);
									@include position(relative);
									@include media($breakpoint-desktop){
										min-height: rem(300px);
									}

								}

								&:first-child {

									> div {
										width: 50%;
										float: left;
										@include media($breakpoint-phone){
											float: none;
											width: 100%;
										}

										&:last-of-type {
											@include border-top-radius(10px);
											@include border-left-radius(0);
											@include media($breakpoint-phone){
												@include border-top-radius(0);
											}

										}

									}

								}

							}

						}

					}

				}

				&:last-child {
					@include clearfix;

					> div {
						width: 25%;
						float: left;
						min-height: rem(350px);
						@include position(relative);
						@include padding(rem(30px));
						@include media($breakpoint-desktop){
							min-height: rem(300px);
							@include padding(rem(15px));
						}
						@include media($breakpoint-phone){
							float: none;
							width: 100%;
						}

						&:nth-child(2){
							width: 50%;
							@include media($breakpoint-phone){
								float: none;
								width: 100%;
							}
						}

						&:first-of-type {
							@include border-bottom-radius(10px);
							@include border-right-radius(0);
							@include media($breakpoint-phone){
								@include border-bottom-radius(0);
							}

						}

						&:last-of-type {
							background-color: $color-success;
							@include border-bottom-radius(10px);
							@include border-left-radius(0);
							&:hover {
								box-shadow: 0 0 40px rgba(0,0,0,0.3);
								-webkit-box-shadow: 0 0 40px rgba(0,0,0,0.3);
							}
							@include media($breakpoint-phone){
								@include border-bottom-radius(10px);
							}

						}

					}

				}

			}

		}

	}

}
