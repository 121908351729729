// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.sidebar {
	@include padding(null null rem(30px));

	h4 {
		color: $color-white;
		background: $color-primary;
		@include font-size(22px);
		@include margin(null null 0);
		@include padding(rem(13px) rem(30px));
		&:after {
			float: right;
			content: "\f107";
			font-family: FontAwesome;
		}

	}

	section {
		@include margin(null null rem(50px));

		&.widget_nav_menu {

			div {

				> ul {
					@include margin(0);

					> li {
						border-bottom: 1px solid rgba($color-black, 0.1);

						> a {
							display: block;
							background: $color-gray;
							color: $text-color-black;
							border-left: 5px solid transparent;
							@include font-size(20px);
							@include position(relative);
							@include padding(rem(11px) rem(20px) rem(10px) rem(42px));
							&:before {
								content: "\f105";
								font-family: FontAwesome;
								@include position(absolute, rem(11px) null null rem(25px));
							}

						}

						&:hover,
						&.current_page_item,
						&.current-page-ancestor {

							a {
								border-left-color: $color-primary;
							}

						}

						> ul {
							@include margin(0);

							> li {
								&:first-child {

									> a {
										border-top: 1px solid rgba($color-black, 0.1);
									}

								}

								&:last-child {

									> a {
										&:after {
											content: "";
											height: 1px;
											width: 100%;
											background: $color-white;
											@include position(absolute, 100% null null 0);
										}

									}

								}

								> a {
									display: block;
									color: $text-color-black;
									border-bottom: 1px solid rgba($color-black, 0.2);
									@include font-size(16px);
									@include position(relative);
									@include padding(rem(13px) rem(20px) rem(13px) rem(65px));
									&:before {
										content: "\f105";
										font-family: FontAwesome;
										@include position(absolute, rem(11px) null null rem(50px));
									}

								}
								&:hover,
								&.current_page_item {

									a {
										color: $color-primary;
									}

								}

								&:last-child {

									> a {
										border-bottom: 0 none;
									}

								}

							}

						}

					}

				}

			}

		}

		&.widget_headoffice {
			background: $color-gray;
			border-bottom: 1px solid rgba($color-black, 0.1);
			@include font-size(18px);
			@include margin(null null rem(30px));

			div {
				@include padding(rem(20px) rem(30px) rem(40px));

				strong {
					display: block;
					color: $text-color-black;
					font-family: $font-family-base-black;
					@include margin(null null rem(30px));
				}

				p {
					line-height: 1.2;
					color: $text-color-black;
					@include font-size(18px);
				}

				a {
					color: $text-color-black;

					i {
						color: $color-primary;
						@include margin(null rem(10px) null null);
					}

				}

			}

		}

		&.widget_regionaloffice {
			@extend .widget_headoffice;
			@include padding(null null rem(30px));

			div {
				@include padding(null null rem(10px));
			}

		}

	}

}
