// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.columns {
	z-index: 1;
	overflow: hidden;
	background: $color-gray;
	color: $text-color-black;
	@include position(relative);
	@include padding(rem(37px) null rem(45px));
	&:after {
		z-index: -1;
		content: "";
		width: 100%;
		height: 120px;
		display: block;
		background: $color-white;
		@include transform(skewY(-3deg));
		@include position(absolute, null null rem(-50px) 0);
	}

	h4 {
		color: $color-primary;
		font-family: $font-family-base-black;
		@include font-size(24px);
		@include margin(null null rem(33px));
		@include media($breakpoint-phone) {
			@include margin(null null rem(10px));
		}
	}

	p {
		@include margin(null null rem(30px));
	}

	strong {
		color: $color-primary;
		font-family: $font-family-base-black;
	}

	aside {
		@include padding(rem(18px) null null);
	}

}
