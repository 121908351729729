// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.specific {
	z-index: 1;
	overflow: hidden;
	@include position(relative);
	@include padding(rem(75px) null rem(95px));
	&:after {
		z-index: -1;
		content: "";
		width: 100%;
		height: 120px;
		display: block;
		background: $color-gray;
		@include transform(skewY(-3deg));
		@include position(absolute, null null rem(-50px) 0);
	}

	section {

		h2 {
			color: $text-color-black;
			@include font-size(50px);
			@include margin(null null rem(50px));
		}

		h4 {
			color: $color-primary;
			font-family: $font-family-base-black;
			@include font-size(24px);
			@include margin(null null rem(35px));
		}

		p {
			color: $text-color-black;
			@include margin(null null rem(72px));
		}

		ul {
			@include margin(null null rem(75px));

			li {
				color: $text-color-black;
				border-bottom: 1px solid $color-dark-gray;
				@include position(relative);
				@include padding(rem(9px) null rem(9px) 180px);
				@include media($breakpoint-phone) {
					@include padding(rem(9px) null rem(9px) 0);
				}

				span {
					display: block;
					font-family: $font-family-base-black;
					@include position(absolute, rem(8px) null null 0);
					@include media($breakpoint-phone) {
						@include position(static);
					}

				}

			}

		}

	}

	aside {
		max-width: 350px;
		color: $color-white;
		background: $color-primary;
		border-radius: 0 15px 0 15px;
		-webkit-border-radius: 0 15px 0 15px;
		@include margin(rem(15px) auto null);
		@include padding(rem(40px) rem(30px) rem(110px));
		@include media($breakpoint-tablet) {
			max-width: 100%;
		}

		strong {
			display: block;
			line-height: 1.3;
			max-width: 200px;
			font-family: $font-family-base-black;
			@include font-size(24px);
			@include margin(null null rem(50px));
		}

		ul {
			@include margin(0);

			li {
				border-bottom: 1px solid rgba($color-white, 0.2);
				@include font-size(18px);
				@include position(relative);
				@include padding(rem(4px) null rem(4px) rem(50px));
				&:before {
					content: "\f058";
					font-family: FontAwesome;
					@include position(absolute, rem(4px) null null 0);
				}

			}

		}

	}

}

