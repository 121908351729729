// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.latest {
	background: $color-gray;
	@include padding(rem(20px) null rem(55px));

	h4 {
		min-height: 70px;
		color: $text-color-black;
		font-family: $heading-font-family;
		@include media($breakpoint-phone) {
			min-height: 0;
		}

	}

	span {
		display: block;
		line-height: normal;
		font-family: $font-family-base-medium;
		@include font-size(18px);

		i {
			@include margin(null rem(10px) null null);
		}

	}

	section {

		ul {

			li {

				h3 {
					min-height: 70px;
					line-height: 1.2;
					color: $text-color-black;
					font-family: $heading-font-family;
					@include font-size(25px);
					@include media($breakpoint-phone) {
						min-height: 0;
					}

				}

				> div {
					background: $color-white;
					@include margin(null null rem(60px));

					img {
						width: 100%;
					}

					> div {
						@include transition(0.3s);
						@include position(relative);
						@include padding(rem(25px) rem(25px));

						span {
							color: $color-primary;
						}

						p {
							color: $text-color-black;
							@include font-size(18px);
						}

						.button {
							@include transform(translateY(50%));
							@include position(absolute, null null 0 rem(25px));
						}

					}

					&:hover {

						> div {
							box-shadow: 0 7px 15px rgba(#000, 0.2);
							-webkit-box-shadow: 0 7px 15px rgba(#000, 0.2);
						}

					}

				}

			}

		}

	}

	aside {
		background: $color-white;
		@include position(relative);
		@include margin(null null rem(60px));
		@include padding(rem(15px) rem(30px) rem(18px));

		ul {

			li {
				border-bottom: 1px solid $color-dark-gray;
				@include padding(rem(17px) null rem(22px));
				&:last-child {
					border-bottom: 0 none;
				}

				span {
					color: $text-color-black;
				}

				p {
					line-height: 1.3;
					@include margin(0);
					@include font-size(18px);

					a {
						color: darken($text-color-base, 15%);
					}

				}

				&:hover {

					span {
						color: $color-primary;
					}

					p {

						a {
							color: $color-primary;
						}

					}

				}

			}

		}

		.button {
			min-width: rem(160px);
			@include transform(translateY(50%));
			@include position(absolute, null null 0 rem(25px));
		}

	}

}
