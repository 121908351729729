// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.article {

	.article__content {
		@include padding(null null rem(100px));

		p {
			@include margin(null null rem(30px));
		}

		h2 {
			color: $color-black;
			font-family: $heading-font-family;
			@include margin(null null rem(30px));
		}

		h3 {
			color: $text-color-black;
			font-family: $font-family-base-medium;
			@include font-size($font-size-h3);
			@include margin(null null rem(30px));
		}

		h4 {
			line-height: 1.4;
			color: $color-primary;
			font-family: $heading-font-family;
			@include font-size(20px);
			@include margin(null null rem(30px));
		}

		ul {
			@include margin(null null rem(40px));

			li {
				border-bottom: 1px solid rgba($color-black, 0.1);
				@include position(relative);
				@include padding(rem(5px) null rem(5px) rem(30px));
				&:before {
					content: "\f061";
					color: $color-primary;
					font-family: FontAwesome;
					@include position(absolute, rem(5px) null null 0);
				}

				&:hover {
					color: $color-black;
					&:before {
						color: $color-black;
					}
				}

			}

		}

		&.article__content--contact {
			@include padding(null null rem(10px));
		}

	}

	.article__sales {
		color: $text-color-black;
		@include font-size(18px);
		@include padding(null null rem(20px));

		h4 {
			color: $text-color-black;
			@include margin(null null rem(30px));
		}

		p {
			@include margin(null null rem(40px));
		}

		ul {

			li {
				@include margin(null null rem(30px));

				div {
					@include position(relative);

					figure {
						border-bottom: 5px solid rgba($color-primary, 0.8);
						@include position(relative);
						@include margin(0 null rem(20px));
						@include gradient($color-gray, $color-black, 145deg);
						img {
							width: 100%;
							opacity: 0.5;
						}

						span {
							width: 100%;
							display: block;
							color: $color-white;
							font-family: $font-family-base-medium;
							@include margin(0);
							@include padding(null rem(40px) null rem(20px));
							@include position(absolute, null null rem(15px) 0);

							i {
								@include position(absolute, null rem(20px) rem(5px) null);
							}

						}

						&:hover {
							background: $color-primary;
							border-bottom-color: #498fc0;

							img {
								opacity: 0.1;
							}

						}

					}

					span {
						display: block;
						color: $text-color-black;
						font-family: $font-family-base-medium;
						@include margin(null null rem(20px));
					}

					a {
						line-height: 1.2;
						display: inline-block;
						color: $text-color-black;
						font-family: $font-family-base-medium;

						i {
							color: $color-primary;
							@include margin(null rem(15px) null null);
						}

					}

				}

			}

		}

	}

	.article__form {
		@include clearfix;
		@include padding(null null rem(75px));

		h2 {
			color: $text-color-black;
			font-family: $font-family-base-medium;
			@include font-size(48px);
			@include margin(null null rem(30px));
		}

		p {
			line-height: 1.4;
			color: $color-primary;
			font-family: $heading-font-family;
			@include font-size(20px);
			@include margin(null null rem(30px));
		}

		input {
			@include margin(null null rem(20px));
		}

	}

	.article__route {
		@include padding(null null rem(90px));

		h2 {
			color: $color-black;
			@include font-size(48px);
		}

		.article__route__map {

			#map_canvas {
				height: auto !important;
				min-height: rem(590px);
				border: 1px solid rgba($color-primary, 0.3);
				@include margin(null null rem(20px));
			}

		}

		form {
			@include position(relative);
			@include padding(null rem(160px) null null);

			input {
				border: 2px solid $color-dark-gray;
			}

			.button {
				@include position(absolute, 0 0 null null);
			}

		}

	}

	&.article--sidebar {
		@include position(relative);
		@include padding(null null null rem(70px));
		@include media($breakpoint-tablet) {
			@include padding(null null null 0);
		}
		@include media($breakpoint-phone) {
			@include padding(rem(50px) null null null);
		}
		&:before {
			width: 2px;
			height: 100%;
			content: "";
			@include position(absolute, 0 null null rem(28px));
			@include gradient($color-dark-gray 30%, $color-white, 155deg);
			@include media($breakpoint-tablet) {
				display: none;
			}

		}

	}

}