//
// Slick
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */
// # Variables
// =============================================== */
// # Imports
// =============================================== */
@import '../../../../bower_components/slick-carousel/slick/slick.scss';

// # Overrides
// =============================================== */

.slick-slider {

	.slick-arrow {
		z-index: 1;
		border: 0 none;
		width: rem(25px);
		height: rem(40px);
		overflow: hidden;
		text-indent: -999px;
		background-color: transparent;
		@include transform(translateY(-50%));
		@include position(absolute, 50% null null null);

		&.slick-prev {
			left: rem(30px);
			@include background-image(prev, svg, center, no-repeat, 95%);
			&:hover {
				@include background-image(prevhvr, svg, center, no-repeat, 95%);
			}
		}

		&.slick-next {
			right: rem(30px);
			@include background-image(next, svg, center, no-repeat, 95%);
			&:hover {
				@include background-image(nexthvr, svg, center, no-repeat, 95%);
			}
		}

	}

}