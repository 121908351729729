// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.wrapper {
	width: 100%;
	overflow: hidden;
	@include clearfix;
	@include transition(0.3s);
	@include padding(rem(200px) null null);
	@include media($breakpoint-desktop) {
		@include padding(rem(180px) null null);
	}
	@include media($breakpoint-tablet) {
		@include padding(rem(160px) null null);
	}
	@include media($breakpoint-phone) {
		@include padding(0);
	}

	&.wrapper--page {
		font-family: $font-family-base;
		line-height: $line-height-base;
		color: $text-color-base;
		background-color: $page-bg;
		@include font-size($font-size-base);
	}

	&.wrapper--fixed {
		@include padding(rem(180px) null null);
		@include media($breakpoint-desktop) {
			@include padding(rem(160px) null null);
		}
		@include media($breakpoint-tablet) {
			@include padding(rem(140px) null null);
		}
		@include media($breakpoint-phone) {
			@include padding(0);
		}
	}

}
