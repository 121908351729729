// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
@mixin grid-core($size) {
	@for $i from 1 through $grid-columns {
		.col--#{$size}-#{$i} {
			min-height: 1px;
			@include span-columns($i of $grid-columns);
		}

		@if ($size != 'xs') {
			.col--#{$size}-offset-left-#{$i} {
				margin-left: flex-grid($i, $grid-columns) + flex-gutter($grid-columns);
			}

			.col--#{$size}-offset-right-#{$i} {
				margin-right: flex-grid($i, $grid-columns) + flex-gutter($grid-columns);
			}
		}

		&.row--#{$size}-#{$i}n > .col {
			@for $n from 1 through $grid-columns {
				@include omega-reset(#{$n}n, $grid-columns);
			}

			@include omega(#{$i}n);
		}
	}

	.col--#{$size}-offset-left-0 {
		margin-left: 0;
	}

	.col--#{$size}-offset-right-0 {
		margin-right: flex-gutter($grid-columns);
	}
}

// # Elements
// =============================================== */

.row {
	@include row();
	@include grid-core(xs);
	@include media(min-width $screen-xs) {
		@include grid-core(sm);
	}
	@include media(min-width $screen-sm) {
		@include grid-core(md);
	}
	@include media(min-width $screen-md) {
		@include grid-core(lg);
	}
	@include media(min-width $screen-lg) {
		@include grid-core(xlg);
	}

	&.row--flex {
		@include align-items(stretch);
		@include display(flex);
		@include flex-direction(row);
		@include flex-wrap(wrap);
		@include justify-content(flex-start);

		> * {
			max-width: 100%;
		}

		> .col {
			@include display(flex);
		}
	}
}

.col {
	@include span-columns($grid-columns of $grid-columns);
	@include omega;
	@if ($grid-helpers) {
		border: rem(2px) solid red !important;
		background: rgba(red, 0.5) !important;
		@include margin(null null rem($gutter-bottom) null);
	}
}
