// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.blog {

	.blog__list {
		border-bottom: 1px solid $color-dark-gray;
		@include margin(null null rem(30px));
	}

}