// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
$button-padding-vertical: rem(13px) !default;
$button-padding-horizontal: rem(28px) !default;

$button-height: ((rem($font-size-base) * $line-height-base) + ( $button-padding-vertical * 2 )) !default;

$button-font-family: $font-family-base-medium !default;
$button-font-size: $font-size-base !default;
$button-font-weight: 400 !default;

$button-color: #fff !default;
$button-color-hover: #fff !default;
$button-color-disabled: #999 !default;

$button-border-size: rem(0) !default;
$button-border-style: solid !default;
$button-border-color: #2e6da4 !default;
$button-border-color-hover: #286090 !default;
$button-border-color-disabled: #666 !default;
$button-border-radius: rem(25px) !default;

$button-bg: #146eae !default;
$button-bg-hover: darken(#146eae, 5%) !default;
$button-bg-disabled: #efefef !default;

// # Elements
// =============================================== */
.button,
.button:link,
.button:visited {
	line-height: normal;
	display: inline-block;
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
	font-family: $button-font-family;
	font-weight: $button-font-weight;
	color: $button-color;
	touch-action: manipulation;
	white-space: nowrap;
	border: $button-border-size $button-border-style $button-border-color;
	border-radius: $button-border-radius;
	background: $button-bg;
	@include margin(null null 0);
	@include padding($button-padding-vertical $button-padding-horizontal);
	@include user-select(none);
	@include font-size($button-font-size);

	i {
		@include margin(null null null rem(15px));
	}

	&.button--success {
		border-color: #4cae4c;
		background: #5cb85c;

		&:hover,
		&[type='submit']:hover,
		&[type='reset']:hover,
		&[type='button']:hover,
		&:focus,
		&.focus {
			border-color: #4cae4c;
			background-color: #4cae4c;
		}
	}

	&:hover,
	&[type='submit']:hover,
	&[type='reset']:hover,
	&[type='button']:hover,
	&:focus,
	&.focus {
		color: $button-color-hover;
		text-decoration: none;
		border-color: $button-border-color-hover;
		background-color: $button-bg-hover;
	}

	&.button--gray {
		color: $color-black;
		background: #dadadb;
		&:hover,
		&[type='submit']:hover,
		&[type='reset']:hover,
		&[type='button']:hover,
		&:focus,
		&.focus {
			background-color: darken(#dadadb, 5%);
		}

	}

	&.button--white {
		color: $color-primary;
		background: $color-white;
		&:hover {
			background: rgba($color-white, 0.8);
		}

	}

	&.button--round {
		width: rem(50px);
		height: rem(50px);
		text-align: center;
		@include padding(0);
		@include font-size(16px);
		@include border-top-radius(50%);
		@include border-bottom-radius(50%);

		i {
			line-height: rem(48px);
			@include margin(0);
		}

	}

	&:active,
	&.active {
		background-image: none;
	}

	&:disabled,
	&.disabled {
		color: $button-color-disabled;
		background-color: $button-bg-disabled;
	}
}
