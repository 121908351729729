//
// Font-awesome
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */

// # Variables
// =============================================== */

$fa-font-path: '../font-awesome/'; // For referencing Bootstrap CDN font files directly use '//netdna.bootstrapcdn.com/font-awesome/4.4.0/fonts'
$fa-font-size-base: 14px;
$fa-line-height-base: 1;
$fa-css-prefix: fa;
$fa-version: '4.4.0';
$fa-border-color: #eeeeee;
$fa-inverse: #ffffff;
$fa-li-width: (30em / 14);

// # Imports
// =============================================== */

@import '../../../bower_components/font-awesome/scss/font-awesome';

// # Overrides
// =============================================== */
