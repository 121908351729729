// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.share {
	background: $color-gray;
	@include padding(rem(23px) null);
	@include margin(null null rem(50px));
	@include media($breakpoint-phone) {
		display: none;
		@include padding(0);
		@include margin(null null rem(30px));
	}

	p {
		color: $text-color-black;
		@include margin(0);
		@include media($breakpoint-phone) {
			display: none;
		}

		i {
			color: $text-color-base;
			@include margin(null rem(10px));
		}

		a {
			color: $text-color-base;
			&:hover {
				color: $color-primary;
			}
		}

	}

	.ssba {
		z-index: 5;
		text-align: right;
		color: $text-color-black;
		@include font-size(20px);
		@include position(relative);
		@include media($breakpoint-phone) {
			text-align: right;
			@include padding(rem(20px) null);
		}

		> div {
			display: inline-block;
		}

		a {
			width: 24px;
			height: 24px;
			line-height: 24px;
			text-align: center;
			color: $color-white;
			display: inline-block;
			background: $color-dark-gray;
			@include margin(null null null rem(4px));
			&:hover {
				background: $color-primary;
			}
			&:first-of-type {
				@include margin(null null null rem(10px));
			}

			img {
				display: none !important;
			}

			&:after {
				content: "";
				font-size: 14px;
				font-family: FontAwesome;
			}

			&.ssba_facebook_share {
				&:after {
					content: "\f09a";
				}
			}
			&.ssba_twitter_share {
				&:after {
					content: "\f099";
				}

			}
			&.ssba_google_share {
				&:after {
					content: "\f0d5";
				}

			}

		}

	}

	&.share--bottom {
		background: none;
		border-top: 1px solid rgba($color-black, 0.1);
		@include margin(0);
		@include media($breakpoint-phone) {
			display: block;
		}

	}

}