// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.header {
	width: 100%;
	z-index: 100;
	background: $color-white;
	@include position(fixed, 0 null null 0);
	@include media($breakpoint-phone) {
		@include position(static);
	}

	section {
		@include transition(0.3s);
	}

	.header__top {
		text-align: right;
		border-bottom: 1px solid rgba($color-black, 0.1);
		@include font-size(16px);
		@include transition(0.3s);
		@include padding(rem(10px) null rem(6px));
		@include media($breakpoint-tablet) {
			display: none;
		}

		ul {
			@include margin(0);

			li {
				display: inline-block;
				@include margin(null null null rem(8px));

				span {
					display: block;
					@include padding(null rem(10px) null null);
				}

				i {
					color: $color-primary;
					@include margin(null rem(10px) null null);
				}

				a {
					color: $text-color-base;
					&:hover {
						color: $color-primary;
					}

				}

			}

		}

	}

	.header__logo {
		z-index: 5;
		float: left;
		height: rem(100px);
		@include position(relative);
		@include margin(null rem(30px) null null);
		@include padding(rem(17px) rem(16px) null rem(20px));
		@include media($breakpoint-tablet) {
			height: auto;
			@include margin(0);
			@include padding(rem(10px) 0);
		}
		@include media($breakpoint-small) {
			max-width: 240px;
		}
		&:after {
			width: 1px;
			content: "";
			display: block;
			height: rem(50px);
			background: rgba($color-black, 0.1);
			@include position(absolute, rem(22px) 0 null null);
			@include media($breakpoint-tablet) {
				display: none;
			}

		}

		&:before {
			z-index: -1;
			content: "";
			width: 130px;
			height: 130px;
			display: block;
			background: $color-white;
			@include border-top-radius(50%);
			@include border-bottom-radius(50%);
			@include position(absolute, rem(-15px) null null rem(-13px));
			@include media($breakpoint-tablet) {
				display: none;
			}
		}

	}

	.header__opener {
		width: 27px;
		float: right;
		display: none;
		@include position(relative);
		@include padding(7px null);
		@include margin(rem(37px) null null);
		@include media($breakpoint-tablet) {
			display: block;
		}
		@include media($breakpoint-phone) {
			@include margin(rem(45px) null null);
		}
		@include media($breakpoint-small) {
			@include margin(rem(29px) null null);
		}
		&:before {
			content: "";
			height: 3px;
			width: 100%;
			display: block;
			background: $color-primary;
			@include transition(0.3s);
			@include position(absolute, 0 null null 0);
		}

		&:after {
			content: "";
			height: 3px;
			width: 100%;
			display: block;
			background: $color-primary;
			@include transition(0.3s);
			@include position(absolute, null null 0 0);
		}

		span {
			width: 100%;
			height: 3px;
			display: block;
			background: $color-primary;
			@include transition(0.3s);
		}

		&.header__opener--active {

			&:before {
				top: 7px;
				transform: rotate(45deg);
			}
			&:after {
				bottom: 7px;
				transform: rotate(-45deg);
			}

			span {
				opacity: 0;
			}

		}

	}

	.header__tag {
		float: left;
		color: $color-primary-light;
		font-family: $font-family-base-italic;
		@include padding(rem(33px) rem(10px) rem(9px) null);
		@include background-image(tag-line, png, bottom, no-repeat, auto);
		@include media($breakpoint-desktop) {
			display: none;
		}

	}

	.header__search {
		float: right;
		text-align: right;
		@include clearfix;
		@include padding(rem(23px) null rem(26px));
		@include media($breakpoint-tablet) {
			float: none;
			text-align: center;
			background: $color-primary;
			@include padding(rem(10px) 15px);
			@include margin(null -15px);
		}

		cite {
			font-style: normal;
			display: inline-block;
			vertical-align: middle;
			font-family: $font-family-base-italic;
			@include font-size(18px);
			@include padding(null rem(7px));
			@include media($breakpoint-tablet) {
				color: $color-white;
				@include padding(rem(10px) null null);
			}
			@include media($breakpoint-small) {
				display: block;
				@include padding(rem(10px) null);
			}

		}

		select {
			width: 220px;
			height: rem(50px);
			display: inline-block;
			vertical-align: middle;
			color: $text-color-base;
			border: 1px solid rgba($color-info, 0.3);
			@include font-size(18px);
			@include border-top-radius(0);
			@include border-bottom-radius(0);
			@include padding(rem(10px) rem(38px) rem(10px) rem(18px));
			@include media($breakpoint-tablet) {
				float: left;
			}
			@include media($breakpoint-phone) {
				width: 200px;
			}
			@include media($breakpoint-small) {
				width: 100%;
				float: none;
				display: block;
			}

		}

		form {
			width: 230px;
			overflow: hidden;
			height: rem(50px);
			display: inline-block;
			vertical-align: middle;
			@include position(relative);
			@include border-top-radius(25px);
			@include border-bottom-radius(25px);
			@include media($breakpoint-tablet) {
				float: right;
			}
			@include media($breakpoint-phone) {
				width: 200px;
			}
			@include media($breakpoint-small) {
				width: 100%;
				float: none;
				display: block;
			}

			input[type="text"] {
				border: 0 none;
				height: rem(50px);
				background: $color-gray;
				@include font-size(18px);
				@include border-top-radius(25px);
				@include border-bottom-radius(25px);
				@include padding(rem(12px) rem(20px) rem(12px) rem(30px));
			}

			button {
				z-index: 1;
				height: 100%;
				border: 0 none;
				width: rem(35px);
				background: none;
				text-align: left;
				color: $color-primary;
				@include font-size(18px);
				@include position(absolute, 0 0 null null)
			}

		}

	}

	.header__menu {
		z-index: 1;
		background: $color-gray;
		@include transition(0.3s);
		@include position(relative);
		@include gradient($color-primary 10%, #8abdd7 50%, 45deg);
		@include media($breakpoint-tablet) {
			display: none;
		}
		> div {
			@include position(static);
		}

		aside {
			z-index: 1;
			width: 56%;
			float: left;
			text-align: right;
			@include transition(0.3s);
			@include padding(rem(25px) rem(46px) rem(25px) null);
			@include media($breakpoint-desktop) {
				@include padding(rem(18px) null);
			}

			> ul {
				@include margin(0);

				> li {
					display: inline-block;
					@include padding(null rem(17px));
					@include media($breakpoint-desktop-large) {
						@include padding(null rem(10px));
					}
					@include media($breakpoint-desktop) {
						@include padding(null rem(5px));
					}
					&:first-child {
						@include padding(null null null 0);

						> a {
							&:before {
								display: none;
							}

						}

					}

					&:last-child {
						@include padding(null 0 null null);
					}

					> a {
						color: $color-white;
						@include position(relative);
						@include media($breakpoint-desktop-large) {
							@include font-size(18px);
						}
						@include media($breakpoint-desktop) {
							@include font-size(16px);
						}
						&:before {
							width: 1px;
							content: "";
							height: rem(20px);
							background: $color-dark-gray;
							@include position(absolute, rem(2px) null null rem(-16px));
							@include media($breakpoint-desktop) {
								height: rem(15px);
								@include position(absolute, rem(3px) null null rem(-8px));
							}
						}
						&:after {
							content: "";
							width: 100%;
							display: block;
							height: rem(32px);
							border-bottom: 5px solid transparent;
							@include transition(0.3s);
							@include position(absolute, 100% null null null);
							@include media($breakpoint-desktop) {
								height: rem(23px);
							}

						}
						&:hover {
							&:after {
								border-bottom-color: $color-white;
							}

						}

					}

					> div {
						display: none;
						text-align: left;
						background: $color-white;
						color: $text-color-black;
						background-size: auto 100%;
						background-repeat: no-repeat;
						background-position: right top;
						border: 10px solid $color-white;
						box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
						-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
						@include position(absolute, 100% 0 null 0);
						&:after {
							content: "";
							display: block;
							border-top: 10px solid $color-white;
							background: rgba($color-black, 0.5);
							@include position(absolute, 100% -50px -5000px -50px);
						}

						> div {

							> ul {

								> li {

									> div {
										@include padding(rem(35px) null rem(35px) rem(100px));
										@include media($breakpoint-desktop) {
											@include padding(rem(35px) 0);
										}

										p {
											line-height: 1.2;
											@include font-size(18px);
											@include margin(null null rem(23px));
										}

										h2 {
											@include font-size(36px);
											@include margin(null null rem(20px));
										}

										h3 {
											color: $color-primary;
											@include margin(null null rem(38px));
										}

										ul {

											li {

												a {
													display: inline-block;
													@include font-size(18px);
													@include position(relative);
													@include padding(null null null rem(40px));
													&:before {
														content: "\f105";
														font-family: FontAwesome;
														@include position(absolute, 0 null null 0);
													}

												}

											}

										}

										section {
											width: 100%;
											float: right;
											max-width: 350px;
											color: $color-white;
											background: rgba($color-primary-light, 0.8);
											@include position(relative);
											@include margin(rem(95px) null null);
											@include padding(rem(45px) rem(40px) rem(25px));
											@include media($breakpoint-tablet) {
												@include padding(rem(20px));
											}
											@include media($breakpoint-phone) {
												float: none;
												@include margin(rem(30px) null);
											}

											p {
												@include font-size(30px);
											}

											.button {
												background: $color-primary;
												@include border-top-radius(25px);
												@include border-bottom-radius(25px);
												@include transform(translateY(-50%));
												@include padding(rem(13px) rem(25px));
												@include position(absolute, 100% null null rem(40px));
												i {
													@include margin(null null null rem(10px));
												}
												&:hover {
													background: darken($color-primary, 5%);
												}

											}

										}

									}

								}

							}

						}

					}
					&:hover {

						> div {
							display: block;
						}

					}
					&:hover,
					&.current-menu-item {

						> a {

							&:after {
								border-bottom-color: $color-white;
							}

						}

					}

				}

			}

		}

		nav {
			z-index: 1;
			width: 42.5%;
			float: right;
			text-align: right;
			background: $color-gray;
			@include position(relative);
			@include padding(rem(25px) null);
			@include media($breakpoint-desktop) {
				@include padding(rem(18px) null);
			}
			&:after {
				content: "";
				width: 100%;
				height: 100%;
				display: block;
				background: $color-gray;
				@include position(absolute, 0 -2000px null 100%);
			}
			&:before {
				z-index: -1;
				width: 50px;
				height: 100%;
				content: "";
				display: block;
				@include position(absolute, 0 100% null null);
				@include background-image(menu-arrow, svg, left, no-repeat, auto 100%);
			}

			> ul {
				@include margin(0);

				> li {
					display: inline-block;
					@include position(relative);
					@include padding(null rem(15px));
					@include media($breakpoint-desktop-large) {
						@include padding(null rem(10px));
					}
					@include media($breakpoint-desktop) {
						@include padding(null rem(5px));
					}
					&:before {
						width: 1px;
						content: "";
						height: rem(20px);
						background: rgba($color-black, 0.2);
						@include position(absolute, rem(5px) null null rem(-1px));
						@include media($breakpoint-desktop) {
							height: rem(15px);
							@include position(absolute, rem(9px) null null rem(-3px));
						}
					}
					&:first-child {
						@include padding(null null null 0);
						&:before {
							display: none;
						}

					}
					&:last-child {
						@include padding(null 0 null null);
					}

					a {
						font-family: $font-family-base-medium;
						@include media($breakpoint-desktop-large) {
							@include font-size(18px);
						}
						@include media($breakpoint-desktop) {
							@include font-size(16px);
						}
					}

					&.current-menu-item {

						> a {
							color: $color-black;
						}

					}

				}

			}

		}

		.header__menu__logo {
			float: left;
			height: auto;
			display: none;
			@extend .header__logo;
			@include padding(rem(8px) null null);
			@include media($breakpoint-desktop) {
				max-width: 90px;
			}
			&:before {
				display: none;
			}

			&:after {
				top: rem(14px);
				@include media($breakpoint-desktop) {
					top: rem(8px);
				}

			}

		}

		.header__menu__tag {
			display: none;
			@include padding(rem(15px) null null);
			@extend .header__tag;
		}

	}

	.header__mobile {
		display: none;
	}

	&.header--fixed {
		box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

		section {
			max-height: 0;
			overflow: hidden;
			visibility: hidden;
			@include padding(0);
			@include media($breakpoint-tablet) {
				max-height: inherit;
				visibility: visible;
			}

		}

		.header__top {
			max-height: 0;
			overflow: hidden;
			visibility: hidden;
			@include padding(0);
		}

		.header__menu {
			background: $color-gray;

			aside {
				max-height: 0;
				overflow: hidden;
				visibility: hidden;
				@include padding(0);
			}

			.header__menu__logo {
				display: block;
			}

			.header__menu__tag {
				display: block;
			}

		}

	}

}

