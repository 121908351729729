// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.banner {
	width: 100%;
	display: table;
	color: $color-white;
	min-height: rem(355px);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	@include padding(rem(125px) null rem(50px));

	section {
		display: table-cell;
		vertical-align: middle;

		h1 {
			font-family: $font-family-base-black;
			@include font-size($font-size-h1);
			@include margin(null null rem(3px));
		}

		p {
			@include font-size(22px);

			a {
				color: $color-dark-gray;
				&:hover {
					color: $color-white;
				}

			}

			i {
				@include margin(null rem(7px));
			}

		}

	}

}