@font-face {
	font-family: 'Quay Sans ITC Std talic';
	src: url('../fonts/quay-sans/QuaySansITCStd-BookItalic.eot');
	src: url('../fonts/quay-sans/QuaySansITCStd-BookItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/quay-sans/QuaySansITCStd-BookItalic.woff2') format('woff2'),
		url('../fonts/quay-sans/QuaySansITCStd-BookItalic.woff') format('woff'),
		url('../fonts/quay-sans/QuaySansITCStd-BookItalic.ttf') format('truetype'),
		url('../fonts/quay-sans/QuaySansITCStd-BookItalic.svg#QuaySansITCStd-BookItalic') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Quay Sans ITC Std Medium';
	src: url('../fonts/quay-sans/QuaySansITCStd-Medium.eot');
	src: url('../fonts/quay-sans/QuaySansITCStd-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/quay-sans/QuaySansITCStd-Medium.woff2') format('woff2'),
		url('../fonts/quay-sans/QuaySansITCStd-Medium.woff') format('woff'),
		url('../fonts/quay-sans/QuaySansITCStd-Medium.ttf') format('truetype'),
		url('../fonts/quay-sans/QuaySansITCStd-Medium.svg#QuaySansITCStd-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Quay Sans ITC Std Black';
	src: url('../fonts/quay-sans/QuaySansITCStd-Black.eot');
	src: url('../fonts/quay-sans/QuaySansITCStd-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/quay-sans/QuaySansITCStd-Black.woff2') format('woff2'),
		url('../fonts/quay-sans/QuaySansITCStd-Black.woff') format('woff'),
		url('../fonts/quay-sans/QuaySansITCStd-Black.ttf') format('truetype'),
		url('../fonts/quay-sans/QuaySansITCStd-Black.svg#QuaySansITCStd-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Quay Sans ITC Std';
	src: url('../fonts/quay-sans/QuaySansITCStd-Book.eot');
	src: url('../fonts/quay-sans/QuaySansITCStd-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/quay-sans/QuaySansITCStd-Book.woff2') format('woff2'),
		url('../fonts/quay-sans/QuaySansITCStd-Book.woff') format('woff'),
		url('../fonts/quay-sans/QuaySansITCStd-Book.ttf') format('truetype'),
		url('../fonts/quay-sans/QuaySansITCStd-Book.svg#QuaySansITCStd-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}
