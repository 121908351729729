// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.footer {

	.footer__address {
		z-index: 1;
		background: $color-gray;
		@include position(relative);
		@include padding(rem(40px) null null);
		@include media($breakpoint-phone) {
			text-align: center;
		}

		&:after {
			z-index: -1;
			content: "";
			height: 100%;
			display: block;
			background: $color-white;
			@include transform(skewX(5deg));
			@include position(absolute, 0 -1000px null 67%);
			@include media($breakpoint-tablet) {
				@include position(absolute, 0 -1000px null 90%);
			}

		}

		p {
			min-height: 80px;
			@include media($breakpoint-phone) {
				min-height: 0;
			}

		}

		.button {
			@include font-size(18px);
			@include media($breakpoint-tablet) {
				@include font-size(16px);
				@include padding(null rem(10px));
			}

			i {
				@include margin(null rem(10px) null 0);
			}

			&.button--round {
				@include font-size(16px);
				@include margin(null null null rem(5px));
				@include media($breakpoint-tablet) {
					width: rem(45px);
					height: rem(45px);
				}

				i {
					@include margin(0);
					@include media($breakpoint-tablet) {
						line-height: rem(43px);
					}

				}

			}

		}

		address {
			color: $text-color-black;
			border-right: 1px solid rgba($color-dark-gray, 0.7);
			@include font-size(18px);
			@include margin(0 rem(-10px) 0 0);
			@include padding(null rem(20px) rem(38px) null);
			@include media($breakpoint-phone) {
				border: 0 none;
				@include padding(0);
			}

			img {
				@include margin(null null rem(38px));
				@include media($breakpoint-phone) {
					@include margin(null auto);
				}

			}

			p {
				line-height: 1.4;
				@include margin(null null rem(10px));
			}

		}

		h3 {
			color: $text-color-black;
			font-family: $font-family-base-medium;
			@include font-size(25px);
			@include margin(null null rem(35px));
			@include media($breakpoint-phone) {
				@include margin(null null rem(20px));
				@include padding(rem(20px) null null);
			}

		}

		h4 {
			min-height: 60px;
			color: $color-primary;
			font-family: $font-family-base-medium;
			@include font-size(20px);
			@include media($breakpoint-phone) {
				min-height: 0;
				@include padding(rem(20px) null null);
			}

		}

		section {
			@include padding(rem(17px) null null);

			p {
				line-height: 1.4;
				color: $text-color-black;
				@include font-size(18px);
				@include margin(null null rem(10px));
			}

		}

		nav {

			ul {

				li {

					a {
						color: $color-primary;
						display: inline-block;
						@include font-size(18px);
						@include position(relative);
						@include padding(null null null rem(40px));
						&:before {
							content: "\f105";
							font-family: FontAwesome;
							@include position(absolute, 0 null null 0);
						}

						&:hover {
							color: $text-color-black;
						}

					}

				}

			}

		}

		aside {
			text-align: center;

			ul {

				li {
					@include margin(null null rem(5px));
					@include media($breakpoint-phone) {
						display: inline-block;
					}

					a {
						width: rem(50px);
						height: rem(50px);
						color: $color-white;
						display: inline-block;
						@include font-size(25px);
						@include border-top-radius(50%);
						@include border-bottom-radius(50%);

						i {
							line-height: rem(50px);
						}

					}

					&:first-child {

						a {
							background: #9e7fc8;
							&:hover {
								background: darken(#9e7fc8, 5%);
							}

						}

					}

					&:nth-child(2) {

						a {
							background: #80ccdf;
							&:hover {
								background: darken(#80ccdf, 5%);
							}

						}

					}

					&:nth-child(3) {

						a {
							background: #e6bf9b;
							&:hover {
								background: darken(#e6bf9b, 5%);
							}

						}

					}

				}

			}

		}

	}

	.footer__widgets {
		color: $color-white;
		background-color: $color-primary;
		@include padding(rem(55px) null null);
		@include background-image(footer__widgets-bg, png, right top, no-repeat);
		@include media($breakpoint-desktop) {
			text-align: center;
		}

		h3 {
			font-family: $font-family-base-medium;
			@include font-size(25px);
			@include margin(null null rem(28px));
			@include media($breakpoint-phone) {
				@include margin(null null rem(15px));
			}

		}

		section {
			width: 20%;
			float: left;
			min-height: rem(220px);
			@include margin(null null rem(30px));
			@include media($breakpoint-desktop) {
				width: 24%;
				float: none;
				text-align: left;
				vertical-align: top;
				display: inline-block;
			}
			@include media($breakpoint-tablet) {
				width: 30%;
			}

			@include media($breakpoint-phone) {
				width: 100%;
				min-height: rem(0);
				text-align: center;
			}

		}

		ul {
			@include margin(0);

			li {

				a {
					display: inline-block;
					color: rgba($color-white, 0.5);
					@include font-size(18px);
					@include position(relative);
					@include padding(null null null rem(38px));
					@include media($breakpoint-phone) {
						@include padding(null null null rem(15px));
					}
					&:before {
						content: "\f105";
						font-family: FontAwesome;
						@include position(absolute, 0 null null 0);
					}

					&:hover {
						color: $color-white;
					}

				}

			}

		}

	}

	.footer__copyright {
		color: $color-white;
		background-color: $color-primary;
		@include background-image(copy-bg, png, left bottom, no-repeat);
		@include media($breakpoint-tablet) {
			text-align: center;
		}

		section {
			border-top: 1px solid rgba($color-white, 0.3);
			@include padding(rem(24px) null);
		}

		p {
			color: $color-dark-gray;
			@include margin(0);
			@include font-size(18px);

			img {
				display: inline-block;
				@include margin(null rem(5px));
			}

			a {
				color: $color-white;
			}

		}

		ul {
			text-align: right;
			@include margin(0);
			@include media($breakpoint-tablet) {
				text-align: center;
			}

			li {
				display: inline-block;
				@include position(relative);
				@include padding(null rem(13px));
				&:before {
					content: "";
					width: 1px;
					display: block;
					height: rem(20px);
					background: $color-dark-gray;
					@include position(absolute, rem(6px) null null 0);
				}

				&:first-child {
					@include padding(null null null 0);

					&:before {
						display: none;
					}

				}

				&:last-child {
					@include padding(null 0 null null);
				}

				a {
					color: $color-dark-gray;
					@include font-size(18px);
					&:hover {
						color: $color-white;
					}

				}

			}

		}

	}

}
