// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */
// No local variables available

// # Mixins
// =============================================== */

@mixin arrow( $size: rem(10px), $direction: down, $color: inherit ) {
	display: block;
	width: 0;
	height: 0;
	content: '';
	border: ($size / 2) solid transparent;

	@if ($direction == down) {
		$direction: 'top';
	} @else if ($direction == up) {
		$direction: 'bottom';
	} @else if ($direction == left) {
		$direction: 'right';
	} @else if ($direction == right) {
		$direction: 'left';
	} @else {
		@warn '[@include arrow[-*](vars)] $direction can only accept the following values: up, down, left, right. Falling back to default (down)';
		$direction: 'top';
	}

	border-#{$direction}-color: $color;
}

@mixin background-image( $name, $extension: 'png', $position: left center, $repeat: no-repeat, $size: false ) {
	background-image: url(#{$image-path}/#{$name}.#{$extension});
	background-position: $position;
	background-repeat: $repeat;

	@if ($size) {
		background-size: $size;
	}

	@if ($retina) {
		@include hidpi {
			background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$extension});
		}
	}
}

@mixin font-size( $value ) {
	font-size: rem($value);
}

@mixin no-gutter-last-child {
	& > *:last-child {
		@include margin(null null 0);
	}
}

@mixin omega-reset( $nth, $context: null ) {
	@if ($context == null) {
		&:nth-child(#{$nth}) {
			@include margin(null flex-gutter() null null);
		}
	} @else {
		&:nth-child(#{$nth}) {
			@include margin(null flex-gutter($context) null null);
		}
	}

	&:nth-child(#{$nth}+1) {
		clear: none;
	}
}

@mixin gradient($color_1, $color_2, $angle){
	background: $color_1;
	background: -webkit-linear-gradient($angle, $color_1, $color_2);
	background: -o-linear-gradient($angle, $color_1, $color_2);
	background: -moz-linear-gradient($angle, $color_1, $color_2);
	background: linear-gradient($angle, $color_1, $color_2);
}
@mixin heading-line($color){
	&:after {
		content: "";
		display: block;
		width: rem(80px);
		height: rem(5px);
		background: $color;
		@include margin(rem(15px) auto null);
	}
}

@mixin solution-block(){
	@include transition(0.3s);

	h4 {
		color: $text-color-black;
		font-family: $font-family-base-medium;
		@include font-size(25px);
		@include transition(0.3s);
		@include margin(null null rem(35px));
	}

	img {
		width: 100%;
	}

	aside {
		min-height: rem(175px);
		background: $color-white;
		@include position(relative);
		@include padding(rem(40px) rem(30px) rem(30px));

		ul {
			@include margin(0);

			li {
				line-height: 1.3;
				@include margin(0);

				a {
					display: inline-block;
					font-family: $font-family-base-medium;
					@include font-size(18px);
					@include position(relative);
					@include padding(null null null rem(15px));
					&:before {
						content: "\f105";
						font-family: FontAwesome;
						@include position(absolute, 0 null null 0);
					}

				}

			}

		}

		.button {
			@include transform(translateY(-50%));
			@include position(absolute, 100% null null rem(20px));
		}

	}

	&:hover {
		@include transform(translateY(-10px));

		h4 {
			@include transform(translateY(10px));
		}

		aside {
			box-shadow: 0 7px 15px rgba(0,0,0,0.3);
		}

	}

}