// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.slider {
	@include font-size(22px);

	.slider__slide {

		h2 {
			line-height: 1;
			font-family: $font-family-base-black;
			@include font-size(60px);
			@include margin(null null rem(30px));
		}

		.slick-slide {
			z-index: 1;
			color: $color-white;
			min-height: 90vh;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center top;
			@include position(relative);
			&:after {
				z-index: -1;
				content: "";
				width: 100%;
				height: 100%;
				display: block;
				@include position(absolute, 0 null null 0);
				@include gradient(rgba($color-black, 0.9), transparent, 130deg);
			}

		}

		.slick-dots {
			width: $width-base - 20px;
			@include transform(translateX(-50%));
			@include position(absolute, 27% null null 50%);
			@include media($breakpoint-desktop-large) {
				left: 0;
				width: 100%;
				@include padding(null rem(70px));
				@include transform(translateX(0));
			}
			@include media($breakpoint-desktop) {
				@include position(absolute, 20% null null null);
			}
			@include media($breakpoint-phone) {
				@include position(absolute, rem(70px) null null null);
				@include padding(null 15px);
			}

			li {
				width: rem(80px);
				display: inline-block;

				span {
					z-index: 1;
					display: block;
					cursor: pointer;
					width: rem(23px);
					height: rem(23px);
					border: 2px solid transparent;
					@include position(relative);
					@include border-top-radius(50%);
					@include border-bottom-radius(50%);
					&:after {
						z-index: -1;
						content: "";
						height: 1px;
						display: block;
						width: rem(58px);
						background: $color-white;
						@include position(absolute, 50% null null rem(20px));
					}

					cite {
						display: block;
						width: rem(12px);
						height: rem(12px);
						background-color: $color-white;
						@include border-top-radius(50%);
						@include border-bottom-radius(50%);
						@include transform(translate(-47%, -52%));
						@include position(absolute, 50% null null 50%);
						&:before {
							z-index: -1;
							content: "";
							height: 1px;
							display: block;
							width: rem(20px);
							background: $color-white;
							@include position(absolute, rem(6px) rem(8px) null null);
						}
						&:after {
							z-index: -1;
							content: "";
							height: 1px;
							display: block;
							width: rem(20px);
							background: $color-white;
							@include position(absolute, rem(6px) null null rem(8px));
						}

					}

				}

				&:first-child {

					span {

						cite {
							&:before {
								display: none;
							}

						}

					}

				}

				&:last-child {

					span {
						&:after {
							display: none;
						}

						cite {

							&:after {
								display: none;
							}

						}

					}

				}

				&.slick-active {

					span {
						border-color: $color-primary;

						cite {
							background-color: $color-primary;
							&:after {
								display: none;
							}

							&:before {
								display: none;
							}

						}

					}

				}

			}

		}

		.slider__slide__caption {
			@include transform(translateY(-50%));
			@include position(absolute, 50% 0 null 0);
		}

	}

	.slider__button {

		ul {
			@include margin(0 null);

			li {

				a {
					width: 100%;
					display: block;
					text-align: left;
					overflow: hidden;
					background: #586f9a;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-family: $font-family-base-medium;
					@include font-size(30px);
					@include transition(0.3s);
					@include position(relative);
					@include transform(translateY(-50%));
					@include border-left-radius(rem(40px));
					@include border-right-radius(rem(40px));
					@include padding(rem(22px) rem(60px) rem(22px) rem(30px));
					@include media($breakpoint-desktop) {
						@include font-size(22px);
						@include padding(rem(15px) rem(40px) rem(15px) rem(20px));
					}
					@include media($breakpoint-tablet) {
						@include margin(null null rem(20px));
					}
					&:after {
						content: "\f105";
						width: rem(32px);
						height: rem(32px);
						text-align: center;
						font-family: FontAwesome;
						@include transition(0.3s);
						@include border-top-radius(50%);
						@include border-bottom-radius(50%);
						@include transform(translateY(-50%));
						@include position(absolute, 50% rem(30px) null null);
						@include media($breakpoint-desktop) {
							width: rem(25px);
							height: rem(25px);
							@include position(absolute, null rem(10px) null null);
						}

					}
					&:hover {
						background: #586f9a;
						&:after {
							color: #586f9a;
							background: $color-white;
						}

					}

				}

				&:nth-child(2) {

					a {
						background: #83a7c1;
						&:hover {
							background: #83a7c1;
							&:after {
								color: #83a7c1;
							}

						}

					}

				}
				&:nth-child(3) {

					a {
						background: #c7c8c9;
						&:hover {
							background: #c7c8c9;
							&:after {
								color: #c7c8c9;
							}

						}

					}

				}
				&:nth-child(4) {

					a {
						background: #b6cbda;
						&:hover {
							background: #b6cbda;
							&:after {
								color: #b6cbda;
							}

						}

					}

				}

			}

		}

	}

}